// https://github.com/afcapel/stimulus-autocomplete/blob/e2115d641cb1d212573476cda602d69b62f483b5/src/autocomplete.js#L200
import { Autocomplete } from 'stimulus-autocomplete'

interface Postcode {
  postcode: string
  location: string
  country_code: string
}
export default class PostcodeAutocompleteController extends Autocomplete {
  static targets = ['city', 'country']

  initialize() {
    this.postcodeResults = [] as Postcode[]
  }
  doFetch = async (url) => {
    const response = await fetch(url, this.optionsForFetch())

    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`)
    }
    //<div class='dropdown-item' role='option' data-autocomplete-value='#{p.id}'>#{p.postcode} #{p.location}</div>
    this.postcodeResults = await response.json()
    return this.postcodeResults
  }

  replaceResults(json) {
    this.resultsTarget.innerHTML = json.map(p => `<div class='dropdown-item' role='option' data-autocomplete-label='${p.postcode}' data-autocomplete-value='${p.id}'>${p.postcode} ${p.location}</div>`).join('')
    this.identifyOptions()
    if (json.length == 0) {
      this.close()
    } else if (!!this.options) {
      this.open()
    } else {
      this.close()
    }
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const textValue = selected.getAttribute("data-autocomplete-label") || selected.textContent.trim()
    const value = selected.getAttribute("data-autocomplete-value") || textValue
    this.inputTarget.value = textValue

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event("input"))
      this.hiddenTarget.dispatchEvent(new Event("change"))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value: value, textValue: textValue, selected: selected }
      })
    )

    const selectedPostcode = this.postcodeResults.find(p => p.id == value)
    if (!selectedPostcode) return

    this.cityTarget.value = selectedPostcode.location
    this.cityTarget.dispatchEvent(new Event("input"))
    this.countryTarget.value = selectedPostcode.country_code
    this.countryTarget.dispatchEvent(new Event("input"))
  }
}
