import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    employeeCount: String,
    organisationName: String,
    username: String
  }
  static targets = [
    "input",
    "price",
    "pricePerMonth",
    "setupFee",
    "bmsHint",
    "listItemEb",
    "listItemAddons",
    "promotionCode",
    "utmSource",
    "employeeCount",
    "crmPricingContainer" // Container für Infos zum Preis
  ]

  declare employeeCountValue: string
  declare organisationNameValue: string
  declare usernameValue: string
  declare readonly bmsHintTargets: Array<HTMLElement>
  declare readonly pricePerMonthTarget: HTMLElement
  declare readonly priceTarget: HTMLElement
  declare readonly inputTargets: Array<HTMLInputElement>
  declare readonly listItemEbTarget: HTMLElement
  declare readonly listItemAddonsTarget: HTMLElement
  declare readonly promotionCodeTarget: HTMLInputElement
  declare readonly utmSourceTarget: HTMLInputElement
  declare readonly employeeCountTarget: HTMLSelectElement
  declare readonly crmPricingContainerTarget: HTMLElement

  connect() {
    this.update()
  }

  update() {
    const response = fetch("/registration/quote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
      body: JSON.stringify({
        employee_count: this.employeeCount(),
        smartlinks_possible: this.smartlinksPossible(),
        start_date: this.startDate(),
        addons: this.selectedAddons(),
        promotion_code: this.promotionCodeTarget.value,
        akquisition_channel: (this.utmSourceTarget.value) ? this.utmSourceTarget.value : "",
        organisation_name: this.organisationNameValue,
        username: this.usernameValue
      })
    }).then(response => response.json()).then(data => {
      this.priceTarget.innerHTML = this.priceHTML(data.totalPrice.netto)
      this.pricePerMonthTarget.innerHTML = `${this.totalPricePerMonth(data.totalPrice.netto).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})} €`
      this.listItemEbTarget.innerHTML = this.listItemEb(data).position
      this.listItemAddonsTarget.innerHTML = this.lineItemsAddonsHtml(this.listItemAddons(data))
      this.crmQuoteResponse().value = JSON.stringify(data)
    }).catch(error => {
      console.log(error)
    })

    if (this.hasBms()) {
      this.bmsHintTargets.forEach(bmsHint => bmsHint.classList.add("inactive"))
    } else {
      this.bmsHintTargets.forEach(bmsHint => bmsHint.classList.remove("inactive"))
    }
  }

  listItemEb(data: Object): any {
    return data.lineItems.find(item => {
      return item.position.match(/Jahresmitgliedschaft.+Empfehlungsbund/) || 
        item.position.match(/Eingeschränkte.+Empfehlungsbund/)
    })
  }

  listItemAddons(data: Object): any {
    return data.lineItems.filter(item => {
      return !item.position.match(/Jahresmitgliedschaft.+Empfehlungsbund/) &&
        !item.position.match(/Eingeschränkte.+Empfehlungsbund/)
    })
  }

  priceHTML(price: number): string {
    let euro = price.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    euro = euro.concat(" €")
    if (this.selectedAddons().length > 0) {
      euro = euro.concat('<span class="text-empfehlungsbund"> *</span>')
    } 
    return euro
  }

  totalPricePerMonth(totalPrice: number): number {
    return Math.round(totalPrice / 12)
  }

  lineItemsAddonsHtml(lineItems: Array<any>): string {
    if (lineItems.length > 0) {
      return `<small><span class='text-empfehlungsbund'>* </span>${lineItems[0].position}</small>`
    } else {
      return ""
    }
  }

  selectedAddons(): Array<string> {
    const selectedAddons = this.inputTargets.filter(input => input.checked).map(input => this.addonMapping(input.id)).filter(addon => addon !== undefined)
    if (selectedAddons.length == 2) {
      return ["bms_complete"]
    } else {
      return selectedAddons
    }
  }
  
  addonMapping(inputId: string): string | any {
    inputId = inputId.replace("registration_partner_form_", "")
    inputId = inputId.replace("registration_record_", "")
    return {
      "needs_crawler": "crawler",
      "needs_application_delivery": "bms_applicant_push"
    }[inputId]
  }

  ebPricePerMonth(): number {
    return this.ebPriceLevel().price.netto / 12
  }

  smartlinksPossible() {
    return this.inputTargets.find(input => input.id.match(/smartlinks_possible/))?.checked 
  }

  hasBms(): boolean | any {
    return this.inputTargets.find(input => input.id.match(/has_bms/))?.checked
  }

  employeeCount(): string | any {
    let employeeCount
    if (this.employeeCountValue !== undefined && this.employeeCountValue !== "") {
      employeeCount = this.employeeCountValue
    } else {
      employeeCount = this.employeeCountTarget.value
    }
    return employeeCount
  }

  startDate(): string | any {
    return this.inputTargets.find(input => input.id.match(/from_date/))?.value
  }

  crmQuoteResponse(): string | any {
    return this.inputTargets.find(input => input.id.match(/crm_quote_response/))
  }
}
