import { Controller } from "@hotwired/stimulus"
import Vue from "vue"

export default class extends Controller {
  static targets = ["input", "component"]
  static values = {
    prefill: String,
  }
  declare inputTarget: HTMLInputElement
  declare componentTarget: HTMLElement
  declare prefillValue: string

  componentTargetConnected() {
    this.prefill()
  }

  prefill() {
    if (this.prefillValue) {
      this.inputTarget.value = this.prefillValue
    }
  }

  mountComponent(kebabName: string, vueComponent: any, prefillKey: string = "value") {
    const that = this
    new Vue({
      el: this.componentTarget,
      render: function (h) {
        return h(kebabName, {
          on: {
            change: (value: string) => {
              that.inputTarget.value = value
            },
            input: (value: string) => {
              that.inputTarget.value = value
            },
          },
          props: {
            [prefillKey]: that.prefillValue,
          },
        })
      },
      components: {
        [kebabName]: vueComponent,
      },
    })
  }
}
