import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import { iframeResize } from "iframe-resizer"

export default class extends Controller {
  connect() {
    const iframe = this.element
    iframeResize(
      {
        checkOrigin: false,
        messageCallback: function (messageData) {
        },
      },
      iframe
    )
    // iframe.attr("scrolling", "yes").css("overflow", "normal")
    iframe.setAttribute("scrolling", "yes")
    iframe.style.overflow = "normal"
  }
}
