import { ApplicationController, useDebounce } from 'stimulus-use'

interface Tag {
  name: string
  searchCount: number
  source: string
}

export default class extends ApplicationController {
  static debounces = [
    {
      name: "fetchTags",
      wait: 1000
    }
  ]
  static targets = ["title", "description", "subjobtype", "portaltype", "keywordResults", "tags"]
  static outlets = ["choices"]

  declare titleTarget: HTMLInputElement
  declare keywordResultsTarget: HTMLDivElement
  declare tagsTarget: HTMLInputElement
  declare descriptionTarget: HTMLTextAreaElement
  declare subjobtypeTarget: HTMLSelectElement
  declare portaltypeTargets: HTMLInputElement[]

  initialize() {
    this.keywordResult = [] as Tag[]
  }

  connect() {
    useDebounce(this, "fetchTags")
    this.fetchTags()
    this.titleTarget.addEventListener("input", () => this.fetchTags())
    this.descriptionTarget.addEventListener("input", () => this.fetchTags())
    this.subjobtypeTarget.addEventListener("change", () => this.fetchTags())
    this.portaltypeTargets.forEach((portaltype) => {
      portaltype.addEventListener("change", () => this.fetchTags())
    })
    window.setTimeout(() => 
      this.choicesOutlet.choices.passedElement.element.addEventListener("removeItem", () => this.renderTags()),
      1000
    )
    window.setTimeout(() => 
      this.choicesOutlet.choices.passedElement.element.addEventListener("addItem", () => this.renderTags()),
      1000
    )
  }

  fetchTags() {
    // http post /next/job-form/auto_tag
    fetch("/next/job-form/auto_tag", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken
      },
      body: JSON.stringify({
        title: this.titleTarget.value,
        text: this.descriptionTarget.value,
        fid: this.fId(),
        portal_type: this.portaltypeTargets.filter((portaltype) => portaltype.checked)[0]?.value
      })
    }).then((response) => response.json()).then((data) => {
      this.keywordResult = data.keyword_info.suggestions
      // this.choicesOutlet.choices.setChoices(this.keywordResult.map((tag) => {
      //   return { value: tag.name, label: tag.name }
      // }), "value", "label", true)
      this.renderTags()
    })
  }

  selectedTags() {
    return this.choicesOutlet.choices.getValue(true)
  }

  renderTags() {
    if (this.keywordResult.length == 0) {
      return
    }
    const notSelectedTags = this.keywordResult.filter((tag) => !this.selectedTags().includes(tag.name))
    let keywordsHtml = ""
    if (notSelectedTags.length > 0) {
      keywordsHtml = "<small class='mt-1 text-muted'>Vorschläge für Schlagworte auf Basis Ihres Titels / Beschreibung (Anklicken zum Übernehmen):</small></br>"
      keywordsHtml += notSelectedTags.
        map((tag) => {
          return `<button 
            data-tag=${JSON.stringify(tag)}
            data-action="click->walk-in-job#addTag:prevent"
            class="btn mb-1 btn-outline-dark"
            >
            ${tag.name}
            </button>`
        }).join(" ")
    }
    this.keywordResultsTarget.innerHTML = keywordsHtml
  }

  addTag(event: Event) {
    const tag = JSON.parse((event.target as HTMLButtonElement).dataset.tag as string) as Tag
    this.tagsTarget.value = this.tagsTarget.value + tag.name + " "
    this.choicesOutlet.choices.setValue([tag.name])
    this.renderTags()
  }

  fId() {
    const selectedOption = this.subjobtypeTarget.selectedOptions[0]
    if (selectedOption) {
      const current = selectedOption.value
      if (["fachkraft", "fuehrungskraft", "freelancer", "hilfskraft"].includes(current)) {
        return "4"
      } else {
        return "5"
      }
    }
  }

  disconnect() {
  }
}

