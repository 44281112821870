import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ["input"]

  declare inputTarget: HTMLTextAreaElement

  connect() {
    this.mask = IMask(this.inputTarget, {
      mask: Number,
      // other options are optional with defaults below
      scale: 2,  // digits after point, 0 for integers
      thousandsSeparator: '.',  // any single char
      padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
      normalizeZeros: true,  // appends or removes zeros at ends
      radix: ',',  // fractional delimiter
      mapToRadix: ['.'],  // symbols to process as radix
      autofix: true,
      removeMaskOnSubmit: true,
    })
  }

  disconnect() {
    this.mask?.destroy()
  }
}
