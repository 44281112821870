import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";
import type { Blob } from "@types/rails__activestorage";

export default class extends Controller {
  static targets = [
    "input",
    "progress",
    "wrapper",
    "imagePreview"
  ]

  declare inputTarget: HTMLInputElement
  declare progressTarget: HTMLProgressElement
  declare wrapperTarget: HTMLElement
  declare imagePreviewTarget: HTMLImageElement

  upload() {
    this.showProgress()
    Array.from(this.inputTarget.files).forEach((file: File) => {
      const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this)
      upload.create((error, blob) => {
        if (error) {
          console.log("Error uploading file:", error)
        } else {
          this.createHiddenBlobInput(blob)
          this.renderImagePreview(blob)
          window.setTimeout(() => {
            this.resetProgress()
            this.hideProgress()
          } , 1000)
        }
      })
    })
  }

  disconnect() {
    this.element.querySelectorAll("input[type=hidden]").forEach((input) => input.remove())
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      this.progressUpdate(event);
    });
  }

  hideProgress() {
    this.wrapperTarget.style.marginBottom = "1rem"
    this.progressTarget.style.marginBottom = "0"
    this.progressTarget.classList.remove("d-block")
    this.progressTarget.classList.add("d-none")
  }

  showProgress() {
    this.wrapperTarget.style.marginBottom = "0"
    this.progressTarget.style.marginBottom = ".5rem"
    this.progressTarget.classList.remove("d-none")
    this.progressTarget.classList.add("d-block")
  }

  resetProgress() {
    if (!this.progressTarget) return

    const bar = this.progressTarget.querySelector(".progress-bar") as HTMLDivElement
    bar.ariaValueNow = "0"
    bar.style.width = "0%"
  }

  progressUpdate(event) {
    const progress = event.loaded / event.total * 100
    const bar = this.progressTarget.querySelector(".progress-bar") as HTMLDivElement
    bar.ariaValueNow = progress.toString()
    bar.style.width = `${progress}%`
  }

  renderImagePreview(blob: Blob) {
    fetch(`/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`).then((response) => {
      if (response.ok) {
        return response.blob()
      }
    } ).then((blob) => {
      console.log(blob)
      const url = URL.createObjectURL(blob)
      this.imagePreviewTarget.src = url
      this.imagePreviewTarget.style.display = "block"
      this.imagePreviewTarget.style.marginBottom = ".5rem"
    })
  }

  createHiddenBlobInput(blob: Blob) {
    const inputId = `${this.inputTarget.id}_hidden`
    const existingInput = this.element.querySelector(`#${inputId}`)
    if (existingInput) {
      existingInput.remove()
    }
    const hiddenField = document.createElement("input")
    hiddenField.id = inputId
    hiddenField.type = "hidden"
    hiddenField.name = this.inputTarget.name
    hiddenField.value = blob.signed_id
    this.element.appendChild(hiddenField)
  }
}
