import { Controller } from "@hotwired/stimulus"

let currentLink: HTMLAnchorElement | null

export default class extends Controller {
  static targets = ["content", "link"]
  static values = {
    currentPath: String,
  }

  declare currentPathValue: string
  declare contentTarget: HTMLElement
  declare linkTargets: HTMLAnchorElement[]

  turn(event: Event) {
    const target = event.target as HTMLElement
    const targetLink = target.closest("a") as HTMLAnchorElement
    const currentPosition = currentLink?.getAttribute("data-position")
    const targetPosition = targetLink.getAttribute("data-position")

    if (currentPosition === targetPosition) {
      return
    }

    if (currentPosition < targetPosition) {
      this.contentTarget.classList.add("turn-exit-top")
      this.contentTarget.classList.add("turn-enter-top")
    }

    if (currentPosition > targetPosition) {
      this.contentTarget.classList.add("turn-exit-bottom")
      this.contentTarget.classList.add("turn-enter-bottom")
    }
  }

  currentPosition() {
    return currentLink?.getAttribute("data-position")
  }

  contentTargetConnected(element: HTMLElement) {}

  // contentTargetDisconnected(element: HTMLElement) {
  // 	this.contentTarget.classList.add('invisible')
  // }

  connect() {
    currentLink = this.linkTargets.find((link: HTMLAnchorElement) =>
      link.href.match(this.currentPathValue + "$")
    )
    this.contentTarget
      .querySelector("form")
      ?.addEventListener("submit", (event) => {
        // this.contentTarget.classList.add('turn-exit-top')
      })
  }

  disconnect() {}
}
