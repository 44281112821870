import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.click)
  }
  click(event: Event) {
    event.preventDefault()
    if (!(event.target instanceof Element)) return

    const fragment = event.target.getAttribute("href")?.replace("#", "") || ""
    if (!fragment) return
    document.dispatchEvent(
      new CustomEvent<{ id: string }>("eb:openModal", { detail: { id: fragment } })
    )
  }
}

const listenForModalId = (id: string, callback: Function) => {
  const listener = (event: Event) => {
    if (event instanceof CustomEvent && event.detail.id === id) {
      callback()
    }
  }
  if (window.location.hash === `#${id}`) {
    callback()
  }
  document.addEventListener("eb:openModal", listener)
  return () => document.removeEventListener("eb:openModal", listener) // return a function to remove the
}
export { listenForModalId }
