import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import { iframeResize } from "iframe-resizer"

export default class extends Controller {
  static values = { url: String }

  connect() {
    const form = $(".new_bms_job_widget_form")
    const subprofileToggle = $(
      'input[name="bms_job_widget_form[subprofiles_enabled]"]'
    )
    const subprofileSelectFormGroup = $(
      "div.form-group.bms_job_widget_form_subprofile_id"
    )
    const subprofileSelect = $(
      'select[name="bms_job_widget_form[subprofile_id]"]'
    )
    const bmsUrl = this.urlValue

    let lastUploadedFile = null
    const refreshPreview = () => {
      const fileField = $(
        ".new_bms_job_widget_form #bms_job_widget_form_banner"
      )
      const banner = fileField[0].files[0]
      // File { name: "chooselife.png", lastModified: 1496658976000, lastModifiedDate: Date 2017-06-05T10:36:16.000Z, webkitRelativePath: "", size: 270842, type: "image/png" }

      if (banner) {
        if (lastUploadedFile == banner.lastModified) {
          fileField.prop("disabled", true)
          // Loeschen/Disable
        } else {
          lastUploadedFile = banner.lastModified
        }
      }
      form.attr("target", "preview_iframe").submit()
      fileField.prop("disabled", false)
    }

    const handleSubprofileInput = (state) => {
      if (state) {
        subprofileSelectFormGroup.show()
        subprofileSelect.prop("disabled", false)
      } else {
        $("#bms_job_widget_form_subprofile_id option:selected").removeAttr(
          "selected"
        )
        subprofileSelect.val("").change()
        subprofileSelectFormGroup.hide()
      }
    }

    subprofileToggle.on("init.bootstrapSwitch", (event, state) => {
      handleSubprofileInput(state)
    })

    $(document).on("click", ".js-save", function (e) {
      form.attr("target", "_self")
      form.attr("action", "/job_widgets")
      form.submit()
    })

    const iframe = $("#preview_iframe")
    iframeResize(
      {
        checkOrigin: false,
        messageCallback: function (messageData) {
          if (messageData.message.indexOf(bmsUrl) != -1) {
            $("#new_bms_job_widget_form").attr("action", messageData.message)
            return
          }
        },
      },
      iframe[0]
    )
    iframe.attr("scrolling", "yes").css("overflow", "normal")

    $(document).ready(() => {
      refreshPreview()

      $(".select_field_multiple").select2({
        theme: "bootstrap",
        width: "100%",
        multiple: true,
      })

      $("#preview_iframe").on("load", function () {
        var window_height = $(window).height()
        $(this).css("max-height", window_height - 140, "important")
      })

      const selectTemplateForFont = function (font) {
        var text = font.text.replace(/\"/g, "").replace(/\'/g, "")
        if (font.text == "google-font") return "Google Schriftarten"
        else if (font.text == "system-font") return "System Schriftarten"
        else
          return $(
            '<span style="font-family: ' +
              text +
              ';padding-left: 1em;">' +
              font.text +
              "</span>"
          )
      }
      $(".select_font_family").select2({
        theme: "bootstrap",
        width: "100%",
        allowclear: false,
        multiple: false,
        templateResult: selectTemplateForFont,
        templateSelection: selectTemplateForFont,
      })
    })

    $(document).ready(function () {
      $(".panel-collapse").on("show.bs.collapse", function () {
        $(this).siblings(".panel-heading").addClass("active")
      })
      $(".panel-collapse").on("hide.bs.collapse", function () {
        $(this).siblings(".panel-heading").removeClass("active")
      })
    })

    $(document).on("click", ".js-refresh", function (e) {
      e.preventDefault()
      refreshPreview()
    })
    $(".new_bms_job_widget_form").on("submit", function () {
      $(".js-loader").removeClass("hide")
    })
    $("iframe").on("load", function () {
      $(".js-loader").addClass("hide")
    })

    // $.fn.bootstrapSwitch.defaults.onText = 'An'
    // $.fn.bootstrapSwitch.defaults.offText = 'Aus'

    $("input[type=checkbox]").on("change", (event, state) => {
      if (
        event.currentTarget.name === "bms_job_widget_form[subprofiles_enabled]"
      ) {
        handleSubprofileInput(
          document.getElementById("bms_job_widget_form_subprofiles_enabled")
            .checked
        )
      }
      if (
        event.currentTarget.name !==
        "bms_job_widget_form[backlink_communities][]"
      )
        refreshPreview()
    })
    $("#new_forms_user_job_widget_filter").submit(function () {
      $(".js-loader").removeClass("hide")
    })
    $("#new_forms_user_job_widget_filter")
      .find("select, input")
      .change(function () {
        $(this).closest("form").submit()
      })
    $("[title]").tooltip()

    $(".nav .nav-link").on("click", function () {
      $(".nav").find(".active").removeClass("active")
      $(this).addClass("active")
    })
  }
}
