import { Controller } from "@hotwired/stimulus"
import * as clipboard from "clipboard-polyfill/text";

// import tooltip from 'utils/simple_tooltip'

export default class extends Controller {
  static values = { content: String }

  declare readonly contentValue: string

  connect() {
  }

  copy() {
    clipboard.writeText(this.contentValue)
    // const popup = tooltip(this.element, "<div class='margin: 15px'>Link wurde kopiert!</div>")
    if (this.element instanceof HTMLElement) {
      this.element.classList.add('btn-success')
      setTimeout(() => {
        this.element.classList.remove('btn-success')
        // popup.hide()
      }, 2000)
    }
  }
}
