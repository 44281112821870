<template lang="pug">
.sectors
  b-overlay(rounded="sm")
    div.mb-1
      div(v-if="selectedSectors.length > 0")
        b-form-tag.mr-1(v-for="sector in selectedSectors" :key="sector.id" variant="dark" @remove="removeSector(sector.id)" :id="sector.id")
          | {{sector.name}}
      div(v-else)
        small.text-muted
          | Fügen Sie Branchen hinzu, um Ihr Profil zu vervollständigen.
    b-button(
      variant="empfehlungsbund"
      @click="modalOpen = true"
      size="sm"
    )
      | bearbeiten
    b-modal(v-model="modalOpen" title="Branche hinzufügen" @ok="modalOpen = false" size="lg" variant="empfehlungsbund" :hide-footer="true")
      .mb-4
        h5
          | Ausgewählte Branchen
        div(v-if="selectedSectors.length > 0")
          .mb-2
            b-form-tag.mr-1(v-for="sector in selectedSectors" :key="sector.id" variant="dark" @remove="removeSector(sector.id)" :id="sector.id" size="lg")
              | {{sector.name}}
        div(v-else)
          small.text-muted
            | Fügen Sie Branchen hinzu, um Ihr Profil zu vervollständigen.
        .text-right
          .btn-group
            div(v-if="selectedSectors.length > 0")
              b-button(
                variant="secondary"
                size="sm"
                @click="selectedSectors = []; $emit('input', [])"
              )
                i.fa.fa-fw.fa-trash
                | Auswahl löschen
            b-button(
              variant="empfehlungsbund"
              size="sm"
              @click="modalOpen = false"
            )
              i.fa.fa-fw.fa-check
              | Auswahl bestätigen
        hr
      b-input-group.mb-2
        template(v-slot:prepend)
          b-input-group-text
            i.fa.fa-search.fa-fw
        b-form-input(type="search" v-model="searchTerm" placeholder="Branche suchen (z.B. IT-Dienstleistungen, Handel ...)")

      template(v-if="loading")
        .row.justify-content-center
          .text-center.my-5
             i.fa.fa-cog.fa-spin.fa-5x.text-empfehlungsbund
      b-form-group(v-else="")
        b-form-checkbox(v-for="item in unselectedSectors" :key="item.id" size="md" :value="item.id" @input="handleSelect($event, item.id)")
          | {{item.name}}
</template>

<script>
import gql from "@/utils/gql2"
import {
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BFormTag,
  BInputGroup,
  BInputGroupText,
  BOverlay,
  BModal
} from 'bootstrap-vue'
export default {
  components: {
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormTag,
    BInputGroup,
    BInputGroupText,
    BFormGroup,
    BOverlay,
    BModal
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modalOpen: false,
      proxyValue: [],
      searchTerm: "",
      list: [],
      availableSectors: [],
      selectedSectors: [],
      loading: false,
    }
  },
  methods: {
    handleSelect(event, id) {
      if (event) {
        this.addSector(id)
      } else {
        this.removeSector(id)
      }
    },
    addSector(id) {
      if (this.selectedSectors.find(s => s.id === id)) {
        return;
      }
      this.selectedSectors.push(this.result.find(s => s.id === id));
      this.$emit('input', this.selectedSectors.map(s => s.id));
    },
    removeSector(id) {
      this.selectedSectors = this.selectedSectors.filter(s => s.id !== id);
      this.$emit('input', this.selectedSectors.map(c => c.id))
    },
  },
  computed: {
    unselectedSectors() {
      return this.result.filter(s => !this.selectedSectors.includes(s))
    },
    result(){
      var result = []
      if (this.searchTerm.trim() === '') {
        result = this.availableSectors
      } else {
        var term = this.searchTerm.trim().toLowerCase();
        result = this.availableSectors.filter(co => {
          let name = co.name.toLowerCase();
          return name.includes(term);
        })
      }
      return result;
    }
  },
  mounted() {
    this.loading = true
    // TODO einzelne Calls für sectors und competences
    gql.getGlobalProfileInfo().then((r) => {
      this.availableSectors = r.allSectors
      if (this.value.length > 0) {
        this.selectedSectors = this.availableSectors.filter(s => this.value.map(v => v.toString()).includes(s.id.toString()))
      }
      this.loading = false
    })
  },
}
</script>

<style scoped>

</style>

