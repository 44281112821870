import { Controller } from "@hotwired/stimulus"

export default class UserAddressesController extends Controller {
  static targets = [
    "userAddress",
    "input",
    "invalidFeedback"
  ]

  declare userAddressTargets: HTMLElement[]
  declare inputTarget: HTMLInputElement
  declare invalidFeedbackTarget: HTMLElement

  connect() {
    this.setAddressIds()
  }

  reload(event: Event) {
    setTimeout(() => { 
      if (this.addressIds().length > 0) {
        console.log(this.addressIds())
        this.invalidFeedbackTarget.classList.add("d-none")
      }
      this.setAddressIds()
    }, 1000) // wait animation
  }

  setAddressIds() {
    this.inputTarget.value = this.addressIds().join(", ")
  }

  addressIds() {
    return this.userAddressTargets.map((address) => { return address.dataset.id })
  }
}
