import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "input", "min", "max"]

  declare selectTarget: HTMLSelectElement
  declare inputTargets: HTMLInputElement[]

  connect() {
    this.inputTargets.forEach((input: HTMLInputElement) => {
      input.addEventListener("input", () => {
        if (input.value !== "") {
          this.selectTarget.required = true
        } else {
          this.selectTarget.required = false
        }
      })
    })
  }

  disconnect() {
    this.inputTargets.forEach((input: HTMLInputElement) => {
      input.removeEventListener("input", () => {
        if (input.value !== "") {
          this.selectTarget.required = true
        } else {
          this.selectTarget.required = false
        }
      })
    })
  }
}

