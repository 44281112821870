import InputVueController from "./input_vue_controller"
import Competences from "../components/Competences.vue"
import Vue from "vue"

export default class VCompetencesController extends InputVueController {
  static values = {
    prefill: Array
  }
  declare prefillValue: Array<number>

  componentTargetConnected() {
    super.componentTargetConnected()
    this.mountComponent("competences", Competences)
  }
}
