import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "toggle", "hint"]
  static values = {
    validations: Array,
  }
  declare inputTarget: HTMLInputElement
  declare toggleTarget: HTMLElement
  declare hintTarget: HTMLElement
  declare validationsValue: Array<{ key: string, regex: string, message: string }>
  // static debounces = [
  //   {
  //     name: 'checkPassword',
  //     wait: 100
  //   }
  // ]

  connect() {
    if (!(this.toggleTarget instanceof HTMLElement)) return
    if (!(this.inputTarget instanceof HTMLInputElement)) return

    this.toggleTarget.addEventListener("mousedown", (event) => this.togglePasswordMask(event, this.inputTarget, this.toggleTarget))
    this.inputTarget.addEventListener("input", (_event) => this.validatePassword())
  }
  disconnect() {
    if (!(this.inputTarget instanceof HTMLInputElement)) return

    this.inputTarget.removeEventListener("input", (_event) => this.validatePassword())
  }
  validatePassword() {
    let validations = this.validationsValue
    let password = this.inputTarget.value
    if (!password) { 
      return []
    }
    let validResults = validations.map(validation => {
      let regex = validation.regex.replace(/[()]/g, "").split(":")[1]

      return {
        key: validation.key,
        valid: RegExp(regex).test(password),
        message: validation.message
      }
    })
    this.hintTarget.innerHTML = `
      <span>Passwort muss mindestens 8 Zeichen lang sein und sollte beinhalten:</span>
      <ul>
        ${validResults.map(validation => `<li class="${validation.valid ? "text-success" : "text-warning"}">${validation.message}</li>`).join("")}
      </ul>`
  }
  togglePasswordMask(event: Event, input: HTMLInputElement, toggle: HTMLElement) {
    event.preventDefault()
    if (!(input instanceof HTMLInputElement)) return
    if (!(toggle instanceof HTMLElement)) return

    let icon: HTMLElement | null = toggle.querySelector("i");
    if (!(icon instanceof HTMLElement)) return

    icon.classList.toggle("fa-eye");
    icon.classList.toggle("fa-eye-slash");
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
    } else {
      input.setAttribute("type", "password");
    }
  }
}
