import '@midzer/choices/public/assets/styles/choices.min.css'
import { Controller } from "@hotwired/stimulus"

import Choices from '@midzer/choices';
export default class extends Controller {
  static targets = ["input"]
  declare inputTarget: HTMLInputElement
  initialize() {
    this.choices;
  }
  connect() {
    this.element[this.identifier] = this
    this.choices = new Choices(this.inputTarget, {
      silent: false,
      items: [],
      choices: [],
      renderChoiceLimit: -1,
      maxItemCount: -1,
      addItems: true,
      addItemFilter: null,
      removeItems: true,
      removeItemButton: true,
      editItems: false,
      allowHTML: true,
      duplicateItemsAllowed: true,
      delimiter: ',',
      paste: true,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      searchResultLimit: 4,
      searchFields: ['label', 'value'],
      position: 'auto',
      resetScrollPosition: true,
      shouldSort: true,
      shouldSortItems: false,
      placeholder: true,
      placeholderValue: null,
      searchPlaceholderValue: null,
      prependValue: null,
      appendValue: null,
      renderSelectedChoices: 'auto',
      loadingText: 'Lade...',
      noResultsText: 'Keine Ergebnisse gefunden',
      noChoicesText: 'Keine Auswahlmöglichkeiten',
      itemSelectText: 'Klicken um auszuwählen',
      addItemText: (value) => {
        return `Drücken Sie Enter um <b>"${value}"</b> hinzuzufügen`;
      },
      maxItemText: (maxItemCount) => {
        return `Nur ${maxItemCount} Werte können ausgewählt werden`;
      },
      valueComparer: (value1, value2) => {
        return value1 === value2;
      },
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        selectedState: 'is-selected',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices'
      },
      labelId: '',
      callbackOnInit: null,
      callbackOnCreateTemplates: null
    })
    // this.inputTarget.style.display = "";
    // this.inputTarget.style.zIndex = -999;
    // this.inputTarget.style.opacity = 0;
    // this.inputTarget.style.position = "absolute";
  }
}
