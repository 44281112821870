import { Controller } from "@hotwired/stimulus"
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/code';
import 'tinymce-i18n/langs/de.js';
import 'tinymce/plugins/table';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/spellchecker';
import 'tinymce/skins/lightgray/skin.min.css';

export default class extends Controller {
  static targets = ["input"]
  static values = { height: Number }

  declare inputTarget: HTMLTextAreaElement
  declare heightValue: number

  initialize() {
    const that = this
    this.options = {
      branding: false,
      browser_spellcheck: true,
      language: "de",
      content_style: `
      body {
      font-family: Verdana,Arial,Helvetica,sans-serif;
      font-size: 14px;
      line-height: 1.3;
      }
      `,
      height: this.heightValue,
      block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;',
      plugins: 'paste link noneditable table colorpicker code lists',
      toolbar1: 'formatselect | bold italic strikethrough forecolor backcolor fontsizeselect h3 h4 h5 | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat code',
      skin: false,
      hidden_input: true,
      selector: `#${this.inputTarget.id}`,
      setup(editor: any) {
        editor.on('change', function () {
          editor.save()
          document.querySelector(`#${that.inputTarget.id}`).dispatchEvent(new Event('input', { bubbles: true }))
        })
      },
    }
  }

  connect() {
    let config = Object.assign({ target: this.inputTarget }, this.options)
    this.tc = tinymce.init(config)
  }

  disconnect() {
    if (!this.preview) tinymce.remove(this.tc.id)
  }

  get preview () {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }
}
