import Highcharts from "~/utils/highcharts"

const whiteAxis = (axis: Highcharts.XAxisOptions | Highcharts.YAxisOptions) => {
  axis.labels ||= {}
  axis.labels.style ||= {}
  axis.lineColor = "#ddd"
  axis.tickColor = "#eee"
  axis.gridLineColor = "#eee"
  axis.labels.style.color = "#fff"
  if (axis.title) {
    axis.title.style ||= {}
    axis.title.style.color = "#fff"
  }
}
const darkAxis = (axis: Highcharts.XAxisOptions | Highcharts.YAxisOptions) => {
  axis.labels ||= {}
  axis.labels.style ||= {}
  axis.lineColor = "#999"
  axis.tickColor = "#999"
  axis.gridLineColor = "#aaa"
  axis.labels.style.color = "#333"
  if (axis.title) {
    axis.title.style ||= {}
    axis.title.style.color = "#333"
  }
}

const medium = (options: Highcharts.Options) => {
  options.chart ||= {}
  options.chart.backgroundColor = "rgba(0,0,0,0)"
  options.plotOptions ||= {}
  options.plotOptions.column ||= {}
  options.plotOptions.column.borderColor = "#555"
  options.xAxis ||= {}
  options.legend ||= {}
  options.legend.itemStyle ||= {}
  options.legend.itemStyle.color = '#fff'
  options.legend.itemStyle.fontWeight = 'normal'
  // options.colors = ["#BF436F", "#13414D", "#bf7530", "#30a2bf"]
  // bessere lesbarkeit
  options.colors = ["#BF436F", "#13414D", "#e09651", "#30a2bf"]
  if ("length" in options.xAxis) options.xAxis.forEach((ax) => whiteAxis(ax))
  else whiteAxis(options.xAxis)
  options.yAxis ||= {}
  if ("length" in options.yAxis) {
    options.yAxis.forEach((ax) => whiteAxis(ax))
  } else {
    whiteAxis(options.yAxis)
  }
}

const white = (options: Highcharts.Options) => {
  options.chart ||= {}
  //options.chart.backgroundColor = "#fff"
  options.plotOptions ||= {}
  options.plotOptions.column ||= {}
  options.plotOptions.column.borderColor = "#ddd"
  options.xAxis ||= {}
  options.yAxis ||= {}
  options.legend ||= {}
  options.legend.itemStyle ||= {}
  options.legend.itemStyle.color = '#333'
  options.legend.itemStyle.fontWeight = 'normal'
  options.colors = ["#BF436F", "#13414D", "#e09651", "#30a2bf"]


  if ("length" in options.xAxis) options.xAxis.forEach((ax) => darkAxis(ax))
  else darkAxis(options.xAxis)
  if ("length" in options.yAxis) {
    options.yAxis.forEach((ax) => darkAxis(ax))
  } else {
    darkAxis(options.yAxis)
  }
}


export { medium, white }
