<template lang='pug'>
div.mt-3
  small.text-muted
    | Filtern nach Portaltypen:
  b-nav.mt-3(tabs fill)
    b-nav-item(v-for="tab in showPortalTypes" :active="tab.active" @click="handleTabClick(tab)" :key="tab.value" active-class="font-weight-bold text-uppercase bg-primary text-white")
      | {{tab.title}}
      app-icon.ml-2(v-if="tab.value" :name="tab.value")
</template>

<script>
import { BNav, BNavItem } from 'bootstrap-vue'
import AppIcon from "next/AppIcon.vue"
export default {
  components: {
    BNav,
    BNavItem,
    AppIcon
  },
  props: ["activePortalTypes"],
  data() {
    return {
      filterTabs: [
        {
          title: "Alle",
          value: null,
          active: true,
          hint: ""
        },
        {
          title: "IT",
          value: "it",
          active: false,
          hint: ""
        },
        {
          title: "MINT",
          value: "mint",
          active: false,
          hint: "Maschinenbau, Elektrotechnik, Naturwissenschaften, Technik"
        },
        {
          title: "SANO",
          value: "sano",
          active: false,
          hint: ""
        },
        {
          title: "OFFICE",
          value: "office",
          active: false,
          hint: "kaufmännischer Bereich, Finanz- und Versicherungsbranche sowie in der öffentlichen Verwaltung"
        },
        {
          title: "LAGER",
          value: "lager",
          active: false,
          hint: "Lager, Fahrer, Fuhrpark"
        },
        {
          title: "BAU",
          value: "bau",
          active: false,
          hint: "Hochbau, Tiefbau, Handwerk"
        }
      ]
    }
  },
  watch: {},
  computed: {
    showPortalTypes() {
      let portalTypes = this.filterTabs
      if (this.activePortalTypes) {
        portalTypes = this.filterTabs.filter(tab => this.activePortalTypes.includes(tab.value) || tab.value == null);
      }
      return portalTypes
    }
  },
  methods: {
    handleTabClick(tab) {
      const selected = this.filterTabs.find(ft => ft === tab)
      const index = this.filterTabs.indexOf(selected)
      for (let i = 0; i < this.filterTabs.length; i++) {
        if (i === index) {
          this.filterTabs[index].active = true
        } else {
          this.filterTabs[i].active = false
        }
      }
      this.$emit('selected', tab.value)
    }
  }
}
</script>

<style lang='sass'>

</style>
