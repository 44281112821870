import { Controller } from "@hotwired/stimulus"

import gql from "@/utils/gql2"

export default class extends Controller {
  static values = { isHtml: Boolean }
  static targets = [ "icon", "spinner", "german", "english"]
  declare isHtmlValue: boolean
  declare iconTarget: HTMLElement
  declare spinnerTarget: HTMLElement
  declare germanTarget: HTMLInputElement
  declare englishTarget: HTMLInputElement

  translate() {
    const val = this.germanTarget.value
    if (val && val.length > 5) {
      this.iconTarget.classList.add("hide")
      this.spinnerTarget.classList.remove("hide")
      gql.translate({ text: val, from: "de", to: "en", isHtml: this.isHtmlValue }).then((res) => {
        if (res.translate) {
          this.englishTarget.value = res.translate
          this.englishTarget.dispatchEvent(new Event("input", { bubbles: true }))
        }
        this.iconTarget.classList.remove("hide")
        this.spinnerTarget.classList.add("hide")
      }).catch((e) => {
        console.error(e)
        this.iconTarget.classList.remove("hide")
        this.spinnerTarget.classList.add("hide")
      })
    } else {
    }
  }
}
