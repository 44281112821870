import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {
  static targets = super.targets.concat(['trigger'])

  declare readonly triggerTarget: HTMLInputElement
  declare readonly itemTargets: HTMLInputElement[]

  connect() {
    super.connect()
    if (
      this.triggerTarget.nodeName === "INPUT" 
        && this.triggerTarget.type === "checkbox"
        && this.triggerTarget.checked
    ) {
      super.show()
    }
  }
  toggle() {
    if (this.triggerTarget.checked) {
      super.show()
    } else {
      super.hide()
      for (const target of this.itemTargets) {
        for (const input of target.querySelectorAll('input')) {
          input.checked = false
        }
      }
    }
  }
}
