import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "icon"]

  declare readonly passwordTarget: HTMLInputElement
  declare readonly iconTarget: HTMLElement

  toggle() {
    this.iconTarget.classList.toggle("fa-eye")
    this.iconTarget.classList.toggle("fa-eye-slash")
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text"
    } else {
      this.passwordTarget.type = "password"
    }
  }
}
