import { Controller } from "@hotwired/stimulus"

import consumer from "@/utils/consumer"

// Object { type: "message_diff", content: "iter", timestamp: 1685445929378.1301 }
type MessagePayload =
  | { type: "message_diff"; content?: string; timestamp: number }
  | { type: "finish", answer: string }
  | { type: "start" }

type ActionCableSubscription = typeof consumer.subscriptions.create

export default class extends Controller {
  static targets = ["output", "cursor", "wrapper"]
  declare readonly outputTarget: HTMLElement
  declare readonly cursorTarget: HTMLElement
  declare readonly wrapperTarget: HTMLElement

  static values = { id: String }
  declare idValue: string

  subscription: ActionCableSubscription | undefined
  messages: string[] = []

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "JobOptimizerChannel", id: this.idValue },
      { received: this.received.bind(this) }
    )
  }
  received(message: MessagePayload) {
    if (message.type === "message_diff" && message.content) {
      this.messages.push(message.content)
      this.outputTarget.textContent += message.content
    } else if (message.type === "finish") {
      this.cursorTarget.classList.add("hidden")
      this.outputTarget.textContent += message.answer
    } else if (message.type === "start") {
      this.cursorTarget.classList.remove("hidden")
      this.outputTarget.textContent = ""
    }
    this.wrapperTarget.scrollTop = this.wrapperTarget.scrollHeight
  }
  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
