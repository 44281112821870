import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"
import Highcharts from "@/utils/highcharts"
import { white, medium } from './themes'

export default class extends Controller {
  static values = {
    chart: String,
    immediate: Boolean,
  }
  declare chartValue: string
  declare immediateValue: boolean
  isVisible = false
  options = {
    threshold: 0,
  }
  stop: Function | null = null
  connect() {
    if (this.immediateValue) {
      this.appear()
    } else {
      const [_, stop] = useIntersection(this, this.options)
      this.stop = stop
    }
  }
  appear() {
    if (this.stop)
      this.stop()
    Highcharts.chart(this.element as HTMLElement, this.chartOptions())
  }

  chartOptions() {
    const options = this.jsonChartOptions()

    white(options)
    return options
  }

  jsonChartOptions() {
    return JSON.parse(this.chartValue || "{}")
  }
}
