import InputVueController from "./input_vue_controller"
import Sectors from "../components/Sectors.vue"
import Vue from "vue"

export default class VSectorsController extends InputVueController {
  static values = {
    prefill: Array
  }
  declare prefillValue: Array<number>

  componentTargetConnected() {
    super.componentTargetConnected()
    this.mountComponent("sectors", Sectors)
  }
}
