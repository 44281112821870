<template lang="pug">
.competences
  b-overlay(rounded="sm")
    div.mb-1
      template(v-if="loading")
        i.fa.fa-cog.fa-spin.text-empfehlungsbund
      div(v-if="selectedCompetences.length > 0")
        b-form-tag.mr-1(v-for="competence in selectedCompetences" :key="competence.id" variant="dark" @remove="handleRemove(competence.id)" :id="competence.id")
          | {{competence.mainWord}}
          b-tooltip(:target="competence.id" triggers="hover")
            | Synonyme: {{getSynonymsTooltip(competence.id)}}
      div(v-else)
        small.text-muted
          | Fügen Sie Kompetenzen hinzu, um Ihr Profil zu vervollständigen.
    b-button(
      variant="empfehlungsbund"
      @click="modalOpen = true"
      size="sm"
    )
      | bearbeiten
    b-modal(v-model="modalOpen" title="Kompetenz hinzufügen" @ok="modalOpen = false" size="lg" variant="empfehlungsbund" :hide-footer="true")
      .mb-4
        h5
          | Ausgewählte Kompetenzen
        div(v-if="selectedCompetences.length > 0")
          .mb-2
            b-form-tag.mr-1(v-for="competence in selectedCompetences" :key="competence.id" variant="dark" @remove="handleRemove(competence.id)" :id="competence.id" size="lg")
              | {{competence.mainWord}}
              b-tooltip(:target="competence.id" triggers="hover")
                | Synonyme: {{getSynonymsTooltip(competence.id)}}
        div(v-else)
          small.text-muted
            | Fügen Sie Kompetenzen hinzu, um Ihr Profil zu vervollständigen.
        .text-right
          .btn-group
            div(v-if="selectedCompetences.length > 0")
              b-button(
                variant="secondary"
                size="sm"
                @click="selectedCompetences = []; $emit('input', [])"
              )
                i.fa.fa-fw.fa-trash
                | Auswahl löschen
            b-button(
              variant="empfehlungsbund"
              size="sm"
              @click="modalOpen = false"
            )
              i.fa.fa-fw.fa-check
              | Auswahl bestätigen
        hr
      h5 Suche
      b-input-group.mb-2
        template(v-slot:prepend)
          b-input-group-text
            i.fa.fa-search.fa-fw
        b-form-input(type="search" v-model="searchTerm" placeholder="Kompetenz suchen (z.B. Javascript, Java ...)")
      portal-type-filter(@selected="handleSelected" :activePortalTypes="activePortalTypes ")
      ul.list-group
        li.list-group-item.d-flex.justify-content-between.align-items-center(v-for="item in competenceShowResult" :key="item.id")
          .order-1.flex-wrap.flex-column
            b-form-checkbox(:value="item.id" @input="handleSelect(item.id)" size="lg")
              | {{item.mainWord}}
            .d-flex.flex-row.flex-wrap.align-items-center
              small.text-muted.mr-2
                | Synonyme:
              span.badge.badge-pill.badge-outline-primary(v-for="(keyword, index) in item.keywords.slice(0, 3)" :key="index")
                | {{keyword}}
          .order-2.p-2.d-flex.flex-column
            small.w-100.text-muted
              | Portaltypen:
            .d-flex.flex-row
              app-icon.mr-1(v-for="(pt, index) in getPortalTypes(item)" :name="pt" :key="index" width="30")
</template>

<script>
import gql from "@/utils/gql2"
import {
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BFormTag,
  BInputGroup,
  BInputGroupText,
  BOverlay,
  BTooltip,
  BModal,
} from 'bootstrap-vue'
import AppIcon from "next/AppIcon.vue"
import PortalTypeFilter from "next/profile/components/competence/PortalTypeFilter.vue"
export default {
  components: {
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormTag,
    BInputGroup,
    BInputGroupText,
    BFormGroup,
    BOverlay,
    BTooltip,
    BModal,
    AppIcon,
    PortalTypeFilter,
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      loading: false,
      modalOpen: false,
      availableCompetences: [],
      selectedCompetences: [],
      searchTerm: "",
      filteredCompetences: [],
      portalTypeFilter: null,
      activePortalTypes: []
    }
  },
  methods: {
    handleSelect(id) {
      this.selectedCompetences.push(this.availableCompetences.find(c => c.id === id))
      this.$emit('input', this.selectedCompetences.map(c => c.id))
    },
    handleSelected(portalType) {
      this.portalTypeFilter = portalType
    },
    handleRemove(id) {
      this.selectedCompetences = this.selectedCompetences.filter(c => c.id !== id)
      this.$emit('input', this.selectedCompetences.map(c => c.id))
    },
    getPortalTypes(item) {
      return item.portalTypes.map(pt => pt.name)
    },
    getSynonymsTooltip(competenceId) {
      let synonyms = this.availableCompetences.find(comp => comp.id === competenceId).keywords.join(', ')
      return synonyms
    },
    getActivePortalTypes() {
      gql.organisationGetProfile().then((r) => {
        const portalTypes = [...new Set(r.me.organisation.memberships.map(m => m.domain.portalType.name))]
        this.activePortalTypes = portalTypes
      })
    }
  },
  computed: {
    competencesShow() {
      let competences = this.availableCompetences
      if (this.portalTypeFilter) {
        competences = competences.filter(c => c.portalTypes.map(pt => pt.name).includes(this.portalTypeFilter))
      }
      if (this.selectedCompetences.length > 0) {
        competences = competences.filter(c => !this.selectedCompetences.map(sc => sc.id).includes(c.id))
      }
      return competences
    },
    competenceShowResult() {
      var result = []
      if (this.searchTerm.trim() === '') {
        result = this.competencesShow
      } else {
        var term = this.searchTerm.trim().toLowerCase();
        result = this.competencesShow.filter(co => {
          let searchSpace = co.keywords.map(el => el.toLowerCase()).join(' ');
          return searchSpace.includes(term) || co.mainWord.toLowerCase().includes(term);
        })
      }
      return result;
    }
  },
  mounted() {
    this.loading = true
    gql.getGlobalProfileInfo().then((r) => {
      this.availableCompetences = r.allCompetences
      if(this.value.length > 0){
        this.selectedCompetences = this.availableCompetences.filter(c => this.value.map(v => v.toString()).includes(c.id.toString()))
      }
      this.getActivePortalTypes()
      this.loading = false
    })
  }
}
</script>

<style scoped>
label.custom-control-label{
  display: block;
}
</style>

