import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["orderMode"]

  declare orderModeTarget: HTMLInputElement

  connect() {
  }

  disconnect() {
  }
}
