<template lang='pug'>
  .color-picker
    div(ref="picker")
</template>

<script>
import "@simonwep/pickr/dist/themes/nano.min.css";
import Pickr from "@simonwep/pickr";
export default {
  props: {
    initalValue: {
      type: String,
      default: "#42445a",
    },
  },
  mounted() {
    this.pickrInstance = Pickr.create({
      el: this.$refs.picker,
      theme: "nano",
      default: this.initalValue,
      defaultRepresentation: "HEX",
      comparison: false,
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          rgba: false,
          hex: false,
          cmyk: false,
          input: false,
          save: true
        }
      },
      i18n: {
        'btn:save': 'Speichern',
      }
    })
    this.pickrInstance.on('change', (color) => {
      const hexColor = color.toHEXA().toString()
      this.setColor(hexColor)
    })
    this.pickrInstance.on( "save", this.saveColor.bind( this ));
    if ( !window.pickrInstance ) {
      window.pickrInstance = this.pickrInstance;
      this.globalInstance = true;
    }
  },
  computed: {},
  methods: {
    setColor(color){
      this.$emit("change", color);
    },
    saveColor(color) {
      const hexColor = color.toHEXA().toString()
      this.$emit( "input", hexColor);
      this.pickrInstance.hide();
    },
  },
  destroy() {
    this.pickrInstance?.destroyAndRemove();
    if ( this.globalInstance ) {
        window.pickrInstance = null;
    }
  }
}
</script>

<style>
.pickr .pcr-button {
  height: 2.2em;
  width: 2.2em;
}
</style>
